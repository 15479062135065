/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
// import Router from 'next/router';
import ShoppingCartIcon from '@root/public/assets/img/icon/bag-happy.svg';
import Badge from '@common/Badge';
import cx from 'classnames';

const WithLink = (props) => {
    const { cartData, handleLink, isCartPage } = props;
    return (
        <div
            className={cx('group hover:cursor-pointer relative')}
            onClick={!isCartPage ? () => handleLink() : () => {
                // Router.push('/checkout/cart');
                window.location.href = '/checkout/cart';
            }}
        >
            <ShoppingCartIcon
                className={cx(
                    'mobile:max-tablet:w-[20px]',
                    'tablet:w-[24px]',
                    'text-neutral-600',
                    'hover:text-oppo_green-500',
                    'group-hover:text-oppo_green-500',
                    'mobile:max-tablet:mt-0',
                    'hover:cursor-pointer',
                )}
            />
            <Badge
                label={cartData}
                fontSize={10}
                className={
                    cx(
                        'absolute !bg-neutral-black rounded-full -top-space-200 desktop:-right-space-150 tablet:-right-space-150 mobile:-right-space-150',
                        'flex justify-center items-center w-[22px] h-[22px] text-xs !text-neutral-white',
                    )
                }
            />
        </div>
    );
};

export default WithLink;
